<template>
  <el-select
    clearable
    :value="value"
    @input="$emit('input', $event)"
    size="mini"
  >
    <el-option
      v-for="item in reportFormats"
      :key="item"
      :label="item"
      :value="item"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'Format',
  props: ['value'],
  data () {
    return {
      reportFormats: ['xml', 'docx', 'pdf']
    }
  }
}
</script>

<style scoped>

</style>
